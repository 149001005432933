<template>
  <div class="query_wraps">
    <h1 class="h_none">商标查询</h1>
    <h2 class="h_none">蓝灯鱼商标查询,蓝灯鱼商标检索,商标,商标查询,商标检索</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <!-- <headers></headers> -->
    <div class="interpretPay">
      <div class="interpretPay_title">
        <p>专利翻译</p>
        <p>本产品同时支持在线应用及API接口调用</p>
      </div>

      <div class="interpretPay_conent">
        <!--    语言方向-->
        <div class="language">
          <p class="language_title">语言方向</p>
          <div class="language_btn">
            <li :class="{active:item.autofocus}"  v-for="(item,index) in Language" :key="index">{{item.name}}</li>
          </div>
        </div>

        <!--      周期-->
        <div class="cycle">
          <span class="demonstration">周期</span>
          <div style="margin-top: 25px" class="block">
            <div class="cycle_contents">
              <div class="cycle_contents_period"><span v-for="(item,index) in servicePeriod" :key="index">{{item}}</span></div>
              <el-slider
                class="cycle_content"
                @input="cycle"
                v-model="value2"
                :step="25"
                :marks="marks"
                :show-tooltip="false"
                show-stops>
              </el-slider>
            </div>
          </div>
        </div>

        <!--      支付价格-->
        <div class="price">
          <div style="position: relative" class="price_cen">
            <p class="amount">价格总计: <span style="margin-left: 15px">{{price}}</span>&nbsp;元</p>
            <!--            <span><img v-if="discountFlag == 1"  style="height: 18px;position: absolute;top: -30px;right: -43px;" src="/images/index/active.png" alt=""></span>-->
            <span v-if="discountFlag == 1" class="zhe">5折</span>
            <div class="order_btn">
              <el-button :class="['el-order-btn']" @click="payEvent">立即购买</el-button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <xfooter></xfooter>
  </div>

</template>

<script>
  import { customizedOrder,priceList } from "@/request/trademarkPayment";
  import xfooter from '@/components/common/xfooter'
  import { mapState, mapMutations, mapGetters } from 'vuex'
  import login from "@/components/common/login"
  // 亚洲
  const cityOptions = [
    {
      city:'中国大陆',
      code:'CN',
      ingSrc:'/images/national/zgdl.jpg'
    },
    {
      city:'中国香港',
      code:'HK',
      ingSrc:'/images/national/zgxg.jpg'
    },
    {
      city:'印度尼西亚',
      code:'ID',
      ingSrc:'/images/national/ydnxy.jpg'
    },
    {
      city:'印度',
      code:'IN',
      ingSrc:'/images/national/yd.jpg'
    },
    {
      city:'日本',
      code:'JP',
      ingSrc:'/images/national/rb.jpg'
    },
    {
      city:'土耳其',
      code:'TR',
      ingSrc:'/images/national/trq.jpg'
    },
    {
      city:'韩国',
      code:'KR',
      ingSrc:'/images/national/hg.jpg'
    },
    {
      city:'中国澳门',
      code:'MO',
      ingSrc:'/images/national/am.jpg'
    },
    {
      city:'马来西亚',
      code:'MY',
      ingSrc:'/images/national/mlxy.jpg'
    },
    {
      city:'菲律宾',
      code:'PH',
      ingSrc:'/images/national/flb.jpg'
    },
    {
      city:'新加坡',
      code:'SG',
      ingSrc:'/images/national/xjp.jpg'
    },
    {
      city:'中国台湾',
      code:'TW',
      ingSrc:'/images/national/zgtw.png'
    },
    {
      city:'泰国',
      code:'TH',
      ingSrc:'/images/national/tgs.jpg'
    },
    {
      city:'阿拉伯联合酋长国',
      code:'AE',
      ingSrc:'/images/national/alq.jpg'
    }
  ]
  // 欧洲
  const city = [
    {
      city:'奥地利',
      code:'AT',
      ingSrc:'/images/national/adl.JPG'
    },
    {
      city:'瑞士',
      code:'CH',
      ingSrc:'/images/national/rs.jpg'
    },
    {
      city:'德国',
      code:'DE',
      ingSrc:'/images/national/dg.jpg'
    },
    {
      city:'丹麦',
      code:'DK',
      ingSrc:'/images/national/dm.jpg'
    },
    {
      city:'挪威',
      code: 'NO',
      ingSrc:'/images/national/nw.jpg'
    },
    {
      city:'西班牙',
      code:'ES',
      ingSrc:'/images/national/xby.jpg'
    },
    {
      city:'欧盟',
      code:'EU',
      ingSrc:'/images/national/om.jpg'
    },
    {
      city:'芬兰',
      code:'FI',
      ingSrc:'/images/national/fl.jpg'
    },
    {
      city:'法国',
      code:'FR',
      ingSrc:'/images/national/fg.jpg'
    },
    {
      city:'英国',
      code:'GB',
      ingSrc:'/images/national/yg.jpg'
    },
    {
      city:'葡萄牙',
      code:'PT',
      ingSrc:'/images/national/pty.jpg'
    },
    {
      city:'希腊',
      code:'GR',
      ingSrc:'/images/national/xl.jpg'
    },
    {
      city:'匈牙利',
      code:'HU',
      ingSrc:'/images/national/xyl.jpg'
    },
    {
      city:'爱尔兰',
      code:'IE',
      ingSrc:'/images/national/ael.jpg'
    },
    {
      city:'冰岛',
      code:'IS',
      ingSrc:'/images/national/bd.jpg'
    },
    {
      city:'意大利',
      code:'IT',
      ingSrc:'/images/national/ydl.jpg'
    },
    {
      city:'瑞典',
      code:'SE',
      ingSrc:'/images/national/rd.jpg'
    },
    {
      city:'俄罗斯',
      code:'RU',
      ingSrc:'/images/national/elss.jpg'
    },
    {
      city:'比荷卢经济联盟',
      code:'BX',
      ingSrc:'/images/national/bhl.png'
    }
  ]
  // 北美洲
  const cityOptionsd = [
    {
      city:'加拿大',
      code:'CA',
      ingSrc:'/images/national/jnd.jpg'
    },
    {
      city:'墨西哥',
      code:'MX',
      ingSrc:'/images/national/mxg.jpg'
    },
    {
      city:'美国',
      code:'US',
      ingSrc:'/images/national/mg.jpg'
    },
  ]
  // 南美洲
  const cityOptionsds = [
    {
      city:'阿根廷',
      code:'AR',
      ingSrc:'/images/national/agt.jpg'
    },
    {
      city:'巴西',
      code:'BR',
      ingSrc:'/images/national/bx.jpg'
    },
    {
      city:'智利',
      code:'CL',
      ingSrc:'/images/national/zl.jpg'
    },
  ]
  // 非洲
  const cityOpts = [
    {
      city:'南非',
      code:'ZA',
      ingSrc:'/images/national/nf.jpg'
    }
  ]
  // 大洋洲
  const cityds = [
    {
      city:'澳大利亚',
      code:'AU',
      ingSrc:'/images/national/adly.jpg'
    },
    {
      city:'新西兰',
      code:'NZ',
      ingSrc:'/images/national/xxl.jpg'
    }
  ]
  //知识产权组织
  const propertyOrganization = [
    {
      city:'世界知识产权组织国际局',
      code:'WO',
      ingSrc:'/images/national/zscq.jpg'
    }
  ]

  export default {
    name: "query",
    metaInfo: {
      title: '蓝灯鱼 商标查询', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼商标查询,蓝灯鱼商标检索,商标,商标查询,商标检索,图片识别,商标数据,近似商标,中国商标,商标名,商标分类,商标图片,商标文字,商标图片查询api'
      }, {                 // set meta
        name: 'description',
        content: '基于全球商标图片，按照维也纳编码分类，对图片进行特征提取和机器学习，通过"以图搜图"AI智能方式查询，准确查找出近似图片。同时，支持商标文字查询,商标档案查看等'
      }],
    },
    data () {
      return {
        //价格是否优惠
        discountFlag:0,
        //周期
        period:'一周',
        periodNumber:'0',
        //语言方向
        Language:[{
          name:'中英',
          autofocus:true
        },{
          name:'英中',
          autofocus:true
        },{
          name:'中日',
          autofocus:true
        },{
          name:'日中',
          autofocus:true
        }],
        //总和数据库
        allDatabase:[],
        price:0,
        //价格单
        priceList:[],
        //进度条
        marks: {
          0: '4.2万字符/次',
          25: '18万字符/次',
          50: '60万字符/次',
          75: '120万字符/次',
          100: '250万字符/次',
        },
        numberPeriod:['42000字','180000字','600000字','1200000字','2500000字'],
        servicePeriod:['一周','一个月','三个月','半年','一年'],
        servicePeriods:['weekPrice','monthPrice','quarterPrice','halfYearPrice','yearPrice'],
        // activityHalfYearPrice: 2500
        // activityMonthPrice: 500
        // activityQuarterPrice: 1500
        // activityWeekPrice: 150
        // activityYearPrice: 6000
        serviceActivePeriod:['activityWeekPrice','activityMonthPrice','activityQuarterPrice','activityHalfYearPrice','activityYearPrice'],
        //默认周期
        value2: 0,
        //数据库
        boxShow:false,
        //是否旋转
        isRotate:true,
        //数据库全选
        checked: false,

        //亚洲
        asiaCheckAll: false,
        asiaIsIndeterminate: true,
        // 欧洲
        europeCheckAll: false,
        europeIsIndeterminate: false,
        // 北美洲
        americaCheckAll: false,
        americaIsIndeterminate: false,
        // 南美洲
        southCheckAll: false,
        southIsIndeterminate: false,
        // 非洲
        africaCheckAll: false,
        africaIsIndeterminate: false,
        // 大洋洲
        oceaniaCheckAll: false,
        oceaniaIsIndeterminate: false,

        //亚洲
        asia: ['CN'],
        asiaCity: cityOptions,
        // 欧洲
        europe: [],
        europeCity: city,
        // 北美洲
        america: [],
        americaCity: cityOptionsd,
        // 南美洲
        south: [],
        southCity: cityOptionsds,
        // 非洲
        africa: [],
        africaCity: cityOpts,
        // 大洋洲
        oceania: [],
        OceaniaCity: cityds,
        //知识产权组织
        property: [],
        propertyOrganizationCity:propertyOrganization,

        dialogFormVisible:true,
        dialogClose: false,
        /*动画相关*/
        searchBoxAppear: true,
        inputFocus: false,
        btnHover: false,
        riverHhorseAppear: false,
        /*查询方式*/
        queryMode: "",//image,text
        /*查询条件*/
        firstList: [
          { text: "申请人精确", param: 2, queryWay: "term", classControl: "" },
          { text: "申请号精确", param: 3, queryWay: "term", classControl: "" },
          { text: "名称近似", param: 1, queryWay: "", classControl: "" },
          { text: "申请人近似", param: 2, queryWay: "", classControl: "" },
          { text: "图形近似", param: "", queryWay: "", classControl: "" },
          { text: "申请号近似", param: 3, queryWay: "", classControl: "" },
          { text: "代理公司", param: 4, queryWay: "", classControl: "" },
        ],//param,queryWay爲後臺需要,classControl爲類名控制需要;
        firstChoosed: "",
        /*筛选条件*/
        niceList: [],//尼斯分类
        tmStatusList: [
          { name: "商标已注册", isCheck: false, code: 1 },
          { name: "商标申请中", isCheck: false, code: 2 },
          { name: "初审公告中", isCheck: false, code: 3 },
          { name: "驳回复审中", isCheck: false, code: 4 },
          { name: "商标异议中", isCheck: false, code: 5 },
          { name: "商标无效", isCheck: false, code: 6 },
          { name: "其他情形", isCheck: false, code: 7 }
        ],//法律状态
        appDateList: [],//申请日期
        moreObj: {
          category: false,
          status: false,
          year: false,
        },

        searchText: "",
        pageNo: 1,//当前页数
        pageSize: 20,//每页查询多少条
        total: 0,
        pagesTotal: 0,
        base64Url: "",//需要查询的文件
        imageCodeList: "",//图形编码
        disabledBool: false,
        param: "",//默认为空，  1为商标名称查询/2为申请人查询/3为申请号查询/4为代理公司查询
        selectedArea: "",//selectedArea
        /*结果*/
        // resultList: [2],
        resultList: [],
        switchStyle: false,
        // 用户选择的默认显示形式  表格或图文
        switchStyleDefault: false,
        dialogVisible: false,
        serviceNameType: "",
        resultBool: false,
        countryList: [{
          value: 'CN',
          label: '中国大陆'
        }, {
          value: 'EU',
          label: '欧盟'
        }, {
          value: 'US',
          label: '美国'
        }],
        countryVal: 'CN',
        tag: ''
      }
    },
    components: {
      login,
      xfooter
      //   headers
    },
    created () {
      for (let i = 1; i <= 45; i++) {
        this.niceList.push({
          name: i < 10 ? "0" + i : i, categoryNo: i < 10 ? "0" + i : i, isCheck: false,
        })
      }
    },
    mounted () {
      //中文数据库总和
      this.allDatabase = this.allDatabase.concat(this.asiaCity,this.europeCity,this.americaCity,this.southCity,this.africaCity,this.OceaniaCity,this.propertyOrganizationCity)
      //服务价格表
      priceList({
        "priceType": 'pt_translate',
        "currency": 'CNY'
      }).then((res) => {
        if(res.code == 0){
          this.priceList = res.data
          this.cycle(this.value2)
        }
      })
      if (this.$route.query.source == 'index' && datafromIndex.queryMode) {
        this.searchText = datafromIndex.text;
        this.base64Url = datafromIndex.image;
        this.searchFirstFn();
      }
      this.riverHhorseAppear = true;
      if (!this.tool.getCookie("auth")) {
      } else {
        // this.searchServiceAjax();
      }


    },
    destroyed () {
      $(window).unbind('scroll', this.scrollFn);
    },
    methods: {
      ...mapMutations(['setMe', 'setNoticeList']),
      //语言方向
      autofocus(data){
        if (data.autofocus) {
          return;
        }
        this.Language.forEach((item, index) => {
          item.autofocus = false;
        })
        data.autofocus = !data.autofocus;
      },
      //获取选择的周期
      cycle(data){
        if(this.priceList.length == 0) return;
        switch (data) {
          case 0: this.price= this.priceList[0]['activityWeekPrice'] ? this.priceList[0]['activityWeekPrice'] : this.priceList[0]['weekPrice']; this.discountFlag= this.priceList[0]['activityWeekPrice'] ? 1 : 0; this.period='一周'; this.periodNumber = 0; break;
          case 25: this.price= this.priceList[0]['activityMonthPrice'] ? this.priceList[0]['activityMonthPrice'] : this.priceList[0]['monthPrice']; this.discountFlag= this.priceList[0]['activityMonthPrice'] ? 1 : 0; this.period='一个月'; this.periodNumber = 1; break;
          case 50: this.price= this.priceList[0]['activityQuarterPrice'] ? this.priceList[0]['activityQuarterPrice'] : this.priceList[0]['quarterPrice']; this.discountFlag= this.priceList[0]['activityQuarterPrice'] ? 1 : 0; this.period='三个月'; this.periodNumber = 2; break;
          case 75: this.price= this.priceList[0]['activityHalfYearPrice'] ? this.priceList[0]['activityHalfYearPrice'] : this.priceList[0]['halfYearPrice']; this.discountFlag= this.priceList[0]['activityHalfYearPrice'] ? 1 : 0; this.period='半年'; this.periodNumber = 3; break;
          case 100: this.price= this.priceList[0]['activityYearPrice'] ? this.priceList[0]['activityYearPrice'] : this.priceList[0]['yearPrice']; this.discountFlag= this.priceList[0]['activityYearPrice'] ? 1 : 0; this.period='一年'; this.periodNumber = 4; break;
        }
      },
      //立即购买
      payEvent(){
        if(this.price == 0){
          this.$message({
            message: '请选择周期',
            type: 'warning'
          });
          return;
        }
        localStorage.setItem('orderId','')
        localStorage.setItem('language', '中英；英中；中日；日中')
        localStorage.setItem('period', this.period)
        localStorage.setItem('price', this.price)
        this.$router.push({
          path:'/trademarkApply',
          query:{
            trademark:false,
            numberPeriod: this.numberPeriod[this.periodNumber],
            servicePeriod:  this.periodNumber + 1,
            discountFlag: this.discountFlag == 1 ? 1 : 0
          }
        })
      },
      //数据库
      database(value){
        if(value){
          this.selectAll()
        }else {
          this.cancelAll()
        }
      },
      //点击显示数据库
      rotate(){
        this.isRotate = !this.isRotate
        this.boxShow = !this.isRotate
      },

      //全部选中
      selectAll(){
        //数据库
        this.checked = true
        //亚洲
        this.asiaCheckAll = true
        this.handleCheckAllChange(true)
        // 欧洲
        this.europeCheckAll = true
        this.handleCheckAllChanges(true)
        // 北美洲
        this.americaCheckAll = true
        this.handleCheckAllChangess(true)
        // 南美洲
        this.southCheckAll = true
        this.handleCheckAllChangesss(true)
        // 非洲
        this.africaCheckAll = true
        this.handleCheckAllChangessss(true)
        // 大洋洲
        this.oceaniaCheckAll = true
        this.handleCheckAllChangesssss(true)
      },
      //全部取消
      cancelAll(){
        //数据库
        this.checked = false
        //亚洲
        this.asiaCheckAll = false
        this.handleCheckAllChange(false)
        // 欧洲
        this.europeCheckAll = false
        this.handleCheckAllChanges(false)
        // 北美洲
        this.americaCheckAll = false
        this.handleCheckAllChangess(false)
        // 南美洲
        this.southCheckAll = false
        this.handleCheckAllChangesss(false)
        // 非洲
        this.africaCheckAll = false
        this.handleCheckAllChangessss(false)
        // 大洋洲
        this.oceaniaCheckAll = false
        this.handleCheckAllChangesssss(false)
      },

      //商标支付
      apply(){
        this.$router.push({path:'/trademarkPayment'})
      },
      //-------------------------------------------------选中------------------------------------------
      handleCheckAllChange(val) {
        this.asia = val ?  cityOptions.map(item => item.code) : []
        this.asiaIsIndeterminate = false;
      },
      handleCheckAllChanges(val) {
        this.europe = val ? city.map(item => item.code) : [];
        this.europeIsIndeterminate = false;
      },
      handleCheckAllChangess(val) {
        this.america = val ? cityOptionsd.map(item => item.code) : [];
        this.americaIsIndeterminate = false;
      },
      handleCheckAllChangesss(val) {
        this.south = val ? cityOptionsds.map(item => item.code) : [];
        this.southIsIndeterminate = false;
      },
      handleCheckAllChangessss(val) {
        this.africa = val ?  cityOpts.map(item => item.code) : [];
        this.africaIsIndeterminate = false;
      },
      handleCheckAllChangesssss(val) {
        this.oceania = val ? cityds.map(item => item.code) : [];
        this.oceaniaIsIndeterminate = false;
      },

      //亚洲
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.asiaCheckAll = checkedCount === this.asiaCity.length;
        this.asiaIsIndeterminate = checkedCount > 0 && checkedCount < this.asiaCity.length;
      },
      // 欧洲
      handleCheckedCitiesChanges(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.europeCheckAll = checkedCount === this.europeCity.length;
        this.europeIsIndeterminate = checkedCount > 0 && checkedCount < this.europeCity.length;
      },
      // 北美洲
      handleCheckedCitiesChangess(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.americaCheckAll = checkedCount === this.americaCity.length;
        this.americaIsIndeterminate = checkedCount > 0 && checkedCount < this.americaCity.length;
      },
      //南美洲
      handleCheckedCitiesChangesss(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.southCheckAll = checkedCount === this.southCity.length;
        this.southIsIndeterminate = checkedCount > 0 && checkedCount < this.southCity.length;
      },
      //非洲
      handleCheckedCitiesChangessss(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.africaCheckAll = checkedCount === this.africaCity.length;
        this.africaIsIndeterminate = checkedCount > 0 && checkedCount < this.africaCity.length;
      },
      //大洋洲
      handleCheckedCitiesChangesssss(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.oceaniaCheckAll = checkedCount === this.OceaniaCity.length;
        this.oceaniaIsIndeterminate = checkedCount > 0 && checkedCount < this.OceaniaCity.length;
      },


      //  查询蓝灯鱼当前登录用户的服务列表
      async searchServiceAjax () {
        let data = await this.$axios.get("/lantern/serviceLan/searchService");
        if (data.data.msg == "success") {
          if (data.data.data) {
            this.setTrialList(data.data.data)
            this.init();
          }
        }
      },
      // 去申请
      toApplyEvent () {
        if (!this.tool.getCookie("auth")) {
          this.dialogVisible = true;
        } else {
          this.$router.push({
            path: "/account/trialInfo",
            query: {
              serverType: 3
            }
          })
        }
      },
      closeEvent (done) {
        this.dialogClose = true;
        done();
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
      init () {
        if (this.trialList) {
          for (var i = 0; i < this.trialList.length; i++) {
            if (this.trialList[i].serviceName == 0) {
              this.serviceNameType = this.trialList[i].serviceType
            }
          }
        }
      },
      // 登录弹层隐藏
      loginDialog (data) {
        this.dialogVisible = data;
        // this.init();
        //   if (!this.unLoginBool  && this.serviceNameType == 2) {
        //     this.$router.push({
        //       path: "/account/trialInfo",
        //       query: {
        //         serverType: 2
        //       }
        //     })
        //   }
      },
      blobToDataURL (blob, callback) {
        var a = new FileReader();
        a.onload = function (e) {
          callback(e.target.result);
        };
        a.readAsDataURL(blob);
      },
      queryDataUrl (data) {
        if (data && data.includes("data:image")) return data;
        else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
        else return "";
      },
      searchFirstFn () {
        if(this.tool.getCookie("auth")){

        }else {
          this.dialogVisible = true
          return;
        }
        if (!this.queryMode) {
          this.switchStyleDefault = false;
        } else {
          this.switchStyleDefault = true;
        }
        this.searchFn();
      },
      confirmSizer () {
        this.switchStyleDefault = true;
        this.searchFn();
      },
      resetSizer () {
        this.niceList.map((item) => {
          item.isCheck = false;
        });
        this.tmStatusList.map((item) => {
          item.isCheck = false;
        });
      },
      firstChange (index) {
        let bolBefore = this.firstList[this.firstChoosed].text.includes("图形");
        this.firstChoosed = index;
        let bolAfter = this.firstList[this.firstChoosed].text.includes("图形");
        if (bolAfter) this.queryMode = "image";
        else {
          this.queryMode = "text";
          this.base64Url = "";
        }
        if (!bolBefore && !bolAfter) this.searchFirstFn();
      },
      moreFn (who) {
        this.moreObj[who] = !this.moreObj[who];
      },
      searchFn () {


        if (this.searchText) {
          if (!this.queryMode) {
            let regex = new RegExp("；", "g"),
              regexSemi = new RegExp("^[;]+|[;]+$", "g");
            let searchTextArr = this.searchText.replace(regex, ";").replace(regexSemi, "").split(";");
            let isAppNo = new RegExp(/^[gGaA]?[0-9]+[gGaA]?$/);
            if (searchTextArr.length != 0) {
              for (var i = 0; i < searchTextArr.length; i++) {
                if (!isAppNo.test(searchTextArr[i])) {
                  this.firstChoosed = 2;
                  this.pageNo = 1;
                  // this.queryMode = "text";
                  // this.querySearch_FromDB();
                  this.getJurisdictionAjax();
                  return;
                } else {
                  this.firstChoosed = 1;
                }
              }
            } else {
              if (isAppNo.test(this.searchText)) {
                this.firstChoosed = 1;
              } else {
                this.firstChoosed = 2;
              }
            }
          }
          this.pageNo = 1;
          // this.queryMode = "text";
          // this.querySearch_FromDB();
          this.getJurisdictionAjax();
        } else if (this.base64Url) {
          this.pageNo = 1;
          // this.queryMode = "image";
          // this.graphSearch_FromDB()
          this.getJurisdictionAjaxImage();
        } else {
          this.$message("请输入文字或者上传图片");
        }

        /* this.resultList = [
           {level: '高'},
           {level: '中'},
           {level: '低'},
         ];*/
      },
      goDetail (item) {
        // $(window).unbind('scroll', this.scrollFn);
        window.open('/query/detail' + '?id=' + item.id + '&country=' + item.country);
        /*        this.$router.push({
                  path: "/queryDetail",
                  query: {
                    id: item.id || 852
                  }
                })*/
      },
      aLargerPreview (item) {
        window.open(this.queryDataUrl(item.imageUrl));
        this.resultList.map(item => item.showBig = false);
        item.showBig = true;
      },
      scrollFn () {
        let scrollTop = $(window).scrollTop();    //滚动条距离顶部的高度
        let scrollHeight = $(document).height();   //当前页面的总高度
        let clientHeight = $(window).height();    //当前可视的页面高度
        if (scrollTop + clientHeight + 170 >= scrollHeight) {   //距离顶部+当前高度 >=文档总高度 即代表滑动到底部
          //滚动条到达底部
          let numMax = this.pagesTotal;
          let num = this.pageNo;
          if (num < numMax && this.resultList.length) {//this.resultMarks.length防止
            num++;
            this.loadMore = "加载中......";
            this.handleCurrentChange(num);
          } else {
            this.loadMore = "加载完毕";
          }
          $(window).unbind('scroll', this.scrollFn);
        } else if (scrollTop <= 0) {
          //滚动条到达顶部
          // console.log(4);
          //滚动条距离顶部的高度小于等于0 TODO
        }
      },
      handleCurrentChange (val) {
        this.pageNo = val;
        this.switchStyleDefault = true;
        switch (this.queryMode) {
          case "image": {
            //   this.queryMode = "image";
            //   this.graphSearch_FromDB()
            this.getJurisdictionAjaxImage();
          }
            break;
          case "text": {
            //   this.querySearch_FromDB();
            this.getJurisdictionAjax();
          }
            break;
        }
      },
      // 更新是否是第一次使用服务的状态
      async updateIsFirstClickAjax () {
        let data = await this.$axios.put("/lantern/serviceLan/updateIsFirstClick", {
          params: {
            serviceName: 0
          }
        })
      },
      // 验证蓝灯鱼用户或者未登陆用户是否还有检索次数
      async getJurisdictionAjax () {
        let data = await this.$axios.get("/lantern/trademarkSearchLan/getJurisdiction");
        if (data.data.code == 0) {
          this.queryMode = "text";
          this.querySearch_FromDB();
          this.$axios.put("/lantern/trademarkSearchLan/updateCount")
        } else if (data.data.code == 1) {
          if (data.data.data.isLogin == 'false') {
            //   if (data.data.data.count == "100") {
            //     this.$confirm(this.$t('patentSearch.confirmTitle2'), this.$t('patentSearch.tips'), {
            //       confirmButtonText: this.$t('patentSearch.goLogin'),
            //       cancelButtonText: this.$t('patentSearch.cancelBtn'),
            //       type: 'warning'
            //     }).then(() => {
            //       this.dialogVisible = true;
            //     }).catch(() => {
            //     })
            //   } else {
            this.setMe('');
            this.setNoticeList('');
            this.$message({
              message: data.data.msg
            });
            return;
            //   }
          } else if (data.data.data.isLogin == 'true') {
            if (data.data.data.firstClick == 'true') {
              this.$confirm(this.$t('patentSearch.confirmTitle3'), this.$t('patentSearch.tips'), {
                confirmButtonText: this.$t('patentSearch.apply'),
                cancelButtonText: this.$t('patentSearch.cancelBtn'),
                type: 'warning'
              }).then(() => {
                this.updateIsFirstClickAjax();
                if (data.data.data.isApply == 'false') {
                  this.$message({
                    message: "您已经提交申请，审核通过后，会给您发送通知"
                  });
                } else {
                  this.$router.push({
                    path: "/account/trialInfo",
                    query: {
                      serverType: 3
                    }
                  })
                }
              }).catch(() => {
                this.updateIsFirstClickAjax();
              });
            } else if (data.data.data.firstClick == 'false') {
              if (data.data.data.count == "100") {
                this.$confirm(this.$t('patentSearch.confirmTitle1'), this.$t('patentSearch.tips'), {
                  confirmButtonText: this.$t('patentSearch.apply'),
                  cancelButtonText: this.$t('patentSearch.cancelBtn'),
                  type: 'warning'
                }).then(() => {
                  if (data.data.data.isApply == 'false') {
                    this.$message({
                      message: "您已经提交申请，审核通过后，会给您发送通知"
                    });
                  } else {
                    this.$router.push({
                      path: "/account/trialInfo",
                      query: {
                        serverType: 3
                      }
                    })
                  }
                }).catch(() => {
                })
              } else {
                this.$message({
                  message: data.data.msg
                });
              }
            }
          }
        }
      },
      /*文字查詢*/
      querySearch_FromDB () {
        if (!this.searchText) return;
        //   this.$nuxt.$loading.start()
        this.queryMode = "text";
        this.$axios.post('/common/query/search', {
          current: this.pageNo,
          keywork: this.searchText,
          size: this.pageSize,
          queryWay: this.firstChoosed !== "" ? this.firstList[this.firstChoosed].queryWay : undefined,
          niceList: this.niceChecked || undefined,
          param: this.firstChoosed !== "" ? this.firstList[this.firstChoosed].param : undefined,
          appDate: "",
          tmStatus: this.tmStatusChecked || undefined,
          country: this.countryVal,
          lang: "zh"
        }).then(({ code, data, msg }) => {
          // this.$nuxt.$loading.finish()
          if (data.code == 1) {
            this.$confirm('您的查询次数已用完', this.$t(`patentSearch.tips`), {
              confirmButtonText: '去购买',
              cancelButtonText: this.$t(`patentSearch.cancelBtn`),
              center: true,
              customClass: "time_confirm",
              closeOnClickModal: false
            }).then(() => {
              this.$router.push({
                path: "/personalCenter/productService",
                query: {
                  serverId: 27
                }
              })
            }).catch(() => {
            })
          } else {
            this.searchHandle(data.data);
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      },
      // 验证蓝灯鱼用户或者未登陆用户是否还有检索次数
      async getJurisdictionAjaxImage (params) {
        let data = await this.$axios.get("/lantern/trademarkSearchLan/getJurisdiction");
        if (data.data.code == 0) {
          this.queryMode = "image";
          this.graphSearch_FromDB()
          this.$axios.put("/lantern/trademarkSearchLan/updateCount");
        } else if (data.data.code == 1) {
          if (data.data.data.isLogin == 'false') {
            //   if (data.data.data.count == "100") {
            //     this.$confirm(this.$t('patentSearch.confirmTitle2'), this.$t('patentSearch.tips'), {
            //       confirmButtonText: this.$t('patentSearch.goLogin'),
            //       cancelButtonText: this.$t('patentSearch.cancelBtn'),
            //       type: 'warning'
            //     }).then(() => {
            //       this.dialogVisible = true;
            //     }).catch(() => {
            //     })
            //   } else {
            this.setMe('');
            this.setNoticeList('');
            this.$message({
              message: data.data.msg
            });
            return;
            //   }
          } else if (data.data.data.isLogin == 'true') {
            if (data.data.data.firstClick == 'true') {
              this.$confirm(this.$t('patentSearch.confirmTitle3'), this.$t('patentSearch.tips'), {
                confirmButtonText: this.$t('patentSearch.apply'),
                cancelButtonText: this.$t('patentSearch.cancelBtn'),
                type: 'warning'
              }).then(() => {
                this.updateIsFirstClickAjax();
                if (data.data.data.isApply == 'false') {
                  this.$message({
                    message: "您已经提交申请，审核通过后，会给您发送通知"
                  });
                } else {
                  this.$router.push({
                    path: "/account/trialInfo",
                    query: {
                      serverType: 3
                    }
                  })
                }
              }).catch(() => {
                this.updateIsFirstClickAjax();
              });
            } else if (data.data.data.firstClick == 'false') {
              if (data.data.data.count == "100") {
                this.$confirm(this.$t('patentSearch.confirmTitle1'), this.$t('patentSearch.tips'), {
                  confirmButtonText: this.$t('patentSearch.apply'),
                  cancelButtonText: this.$t('patentSearch.cancelBtn'),
                  type: 'warning'
                }).then(() => {
                  if (data.data.data.isApply == 'false') {
                    this.$message({
                      message: "您已经提交申请，审核通过后，会给您发送通知"
                    });
                  } else {
                    this.$router.push({
                      path: "/account/trialInfo",
                      query: {
                        serverType: 3
                      }
                    })
                  }
                }).catch(() => {
                })
              } else {
                this.$message({
                  message: data.data.msg
                });
              }
            }
          }
        }
      },
      /*图片查询*/
      graphSearch_FromDB () {
        if (!this.base64Url) return;
        //   this.$nuxt.$loading.start()
        this.queryMode = "image";
        this.firstChoosed = 4;
        this.$axios.post('/common/graph/search', {
          current: this.pageNo,
          size: this.pageSize,
          base64File: this.base64Url.replace(/^data:image\/\w+;base64,/, ""),
          imageCodeList: this.imageCodeList || undefined,
          niceList: this.niceChecked || undefined,
          param: undefined,
          selectedArea: this.selectedArea || undefined,
          appDate: "",
          tmStatus: this.tmStatusChecked || undefined,
          country: this.countryVal,
          lang: "zh"
        }).then(({ code, data, msg }) => {
          // this.$nuxt.$loading.finish()
          if (data.code == 1) {
            this.$confirm('您的查询次数已用完', this.$t('patentSearch.tips'), {
              confirmButtonText: '去购买',
              cancelButtonText: this.$t('patentSearch.cancelBtn'),
              center: true,
              customClass: "time_confirm",
              closeOnClickModal: false
            }).then(() => {
              this.$router.push({
                path: "/personalCenter/productService",
                query: {
                  serverId: 27
                }
              })
            }).catch(() => {
            })
          } else {
            this.searchHandle(data.data);
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      },
      /*文字或图片查询的后续处理*/
      searchHandle (data) {
        if (this.pageNo == 1) {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        }
        this.resultBool = true;
        if (this.switchStyleDefault == false) {
          if (this.queryMode == "image") {
            this.switchStyle = true;
          } else if (this.queryMode == "text") {
            this.switchStyle = false;
          }
        }

        $(window).unbind('scroll', this.scrollFn);
        $(window).scroll(this.scrollFn);
        data.records.map(item => {
          item.showName = item.tmapplicant || item.tmapplicant_en || item.applicant_en || item.applicantEn;
          item.showAdress = item.tmaddress || item.tmaddress_en || item.address_en || item.addressEn;
          // item.showTmdetail = item.tmdetail || item.tmdetail_en || item.tmdetailEn;
          item.showTrademarkName = (item.tmcn && item.tmen ? item.tmcn + "--" + item.tmen : item.tmcn || item.tmen);


          // 服务平台图片近似查询结果评分标准：
          // score<=85 高，
          // score>85 and score<=250 中，
          // score>250 低，

          // 服务平台文字近似查询结果评分标准：
          // score>=80 高，
          // score>=20 and score<80 中，
          // score<20 低


          let level = "";
          if (this.queryMode == 'image') {
            if (parseInt(item.score) <= 85) level = '高';
            else if (parseInt(item.score) > 85 && parseInt(item.score) <= 250) level = '中';
            else if (parseInt(item.score) > 250) level = '低';
          } else if (this.queryMode == 'text') {
            if (parseInt(item.score) >= 80) level = '高';
            else if (parseInt(item.score) >= 20 && parseInt(item.score) < 80) level = '中';
            else if (parseInt(item.score) < 20) level = '低';
          }
          item.level = level;

          if (item.type != 'tm_info') {
            try {
              if (item.tmdetailEn.indexOf("niceCode\\\\\":") != -1) {
                item.tmdetailEn = item.tmdetailEn.replaceAll("niceCode\\\\\":", "niceCode\\\\\"");
              }
              item.tmdetail = JSON.parse(item.tmdetailEn || "[]");
              item.tmdetail = item.tmdetail.map(item2 => `${item2.niceCode}:${item2.value}`).join(";");
            } catch (e) {
              item.tmdetail = item.tmdetail_en;
            }
          }
          item.showTmdetail = item.tmdetail || item.tmdetail_en || item.tmdetailEn;
        });
        //22864036,
        this.resultList = this.pageNo == 1 ? data.records : this.resultList.concat(data.records);
        this.$set(this.resultList, 'checked', false)
        this.total = data.total;
        this.pagesTotal = data.pages;
      },
      inputFn () {
        if (this.firstChoosed == 4) this.firstChoosed = 2;
        if (this.firstChoosed === "") this.firstChoosed = 2;
      },
      handleRemove () {
        this.base64Url = "";
        this.$refs.upload.clearFiles();
      },
      uploadChangeFn (file, fileList) {
        if (fileList.length > 1) {
          fileList.splice(0, 1);
        }
        this.blobToDataURL(file.raw, (base64) => {
          this.base64Url = base64;
          this.pageNo = 1;
          this.firstChoosed = 4;
          this.searchText = "";
          // this.queryMode = "image";
          // this.graphSearch_FromDB()
          this.getJurisdictionAjaxImage();
        });
        return;
        this.upload_({ file: file.raw })
          .then(({ code, data, msg }) => {
            if (code) {
              this.$message(msg);
              return;
            }
            this.base64Url = data;
            this.getJurisdictionAjaxImage();
          })
      },
      // 下载
      downEvent () {
        //   this.$nuxt.$loading.start()
        this.$axios.post('common/query/exportExcel', {
          keywork: this.searchText,
          queryWay: this.firstChoosed !== "" ? this.firstList[this.firstChoosed].queryWay : undefined,
          niceList: this.niceChecked || undefined,
          param: this.firstChoosed !== "" ? this.firstList[this.firstChoosed].param : undefined,
          appDate: "",
          tmStatus: this.tmStatusChecked || undefined,
          country: this.countryVal
        }, {
          responseType: 'blob'
        }).then(({ code, data, msg }) => {
          // this.$nuxt.$loading.finish()
          const content = data
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });
          const fileName = '查询结果.xls'
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      }
    },
    computed: {
      ...mapState(['me', 'trialList']),
      // numMax(){
      //   return Math.ceil(this.total/this.pageSize);
      // },
      niceChecked () {
        return this.niceList.filter(item => item.isCheck).map(item2 => item2.categoryNo).join(";");
      },
      tmStatusChecked () {
        return this.tmStatusList.filter(item => item.isCheck).map(item2 => item2.code).join(";");
      },
    },
    watch: {
      $route () {
        this.tag = this.$route.query.tag
      },
      tag (val) {
        if (val == 1 || val == undefined) {
          this.$router.go(0);
        }
      },
      queryMode (val) {
        if (val != "") {
          this.tag = 2;
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .interpretPay{
    width: 1206px;
    margin: 0 auto;

    .interpretPay_conent{
      width: 1206px;
      height: 518px;
      background: #ffffff;
      padding: 50px;
      box-sizing: border-box;
      margin-top: 6px;
      position: relative;
      .language{
        .language_title{
          font-size: 18px;
          color: rgb( 51, 51, 51 );
        }

        /*.el-button:focus, .el-button:hover {*/
        /*  color: #ffffff;*/
        /*  border-color: #2f56de;*/
        /*  background-color: #2f56de;*/
        /*}*/

        .language_btn{
          display: flex;
          margin-top: 25px;
          li{
            cursor:not-allowed;
            list-style: none;
            margin-right: 10px;
            width: 86px;
            height: 42px;
            line-height: 40px;
            font-size: 16px;
            text-align: center;
            border-radius: 10px;
            border: 1px solid #e0e0e0;
            &.active{
              background: #2f56de;
              color: #ffffff;
            }
            /*&:hover{*/
            /*<!--  background: @blue;-->*/
            /*color: #ffffff;*/
            /*}*/
          }

        }

        /deep/.el-button {
          display: inline-block;
          line-height: 1;
          white-space: nowrap;
          cursor: pointer;
          background: #FFF;
          border: 1px solid #DCDFE6;
          color: #606266;
          -webkit-appearance: none;
          text-align: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          outline: 0;
          margin: 0;
          -webkit-transition: .1s;
          transition: .1s;
          font-weight: 500;
          padding: 12px 20px;
          font-size: 14px;
          border-radius: 4px;
        }
      }
      .cycle{
        margin: 31px auto;
        width: 1240px;

        .cycle_contents{
          position: relative;
          padding-left: 30px;
          width: 600px;
          .cycle_contents_period{
            width: 750px;
            top: -13px;
            left: 10px;
            position: absolute;
            span{
              font-size: 14px;
              color: rgb( 51, 51, 51 );
              margin-right: 114px;
            }
          }
          /deep/.el-slider__marks-text:last-child{
            width: 100px!important;
          }
          .cycle_content{
            width: auto;
            /deep/.el-slider__marks-text{
              width: auto;
              font-size: 14px;
              color: rgb( 51, 51, 51 );
              margin-top: 20px;
            }
            /deep/.el-slider__runway{
              height: 12px;
              background: #dce4fe;
              /deep/.el-slider__bar{
                height: 12px;
              }
              /deep/.el-slider__button-wrapper {
                top: -13px;
              }
            }
            /deep/.el-slider__stop {
              height: 12px;
              width: 12px;
            }
          }
        }

      }
      .price{
        width: 1240px;
        margin: 0 auto;
        text-align: right;
        position: absolute;
        bottom: 50px;
        right: 56px;
        .price_cen{
          .zhe{
            margin-right: 3px;
            display: table-cell;
            width: 40px;
            height: 20px;
            background: #ff6a37;
            color: white;
            border-radius: 5px;
            font-size: 14px;
            vertical-align:middle;
            text-align:center;
            margin-left: 8px;
            position: absolute;top: -30px;right: -50px;
          }
          display: inline-block;
          width: 500px;
          text-align: right;
          position: relative;
          .amount{
            position: absolute;
            top: -25px;
            right: 0;
            /*width: 900px;*/
            text-align: right;
            display: inline-block;
            font-size: 16px;
            color: rgb( 51, 51, 51 );
            font-weight: bold;
            padding-left: 12px;
            span{
              font-size: 32px;
              color: rgb( 255, 106, 55 );
            }
          }
          .order_btn {
            position: absolute;
            text-align: right;
            display: inline-block;
            /*width: 900px;*/
            margin: 26px auto 0;
            position: relative;
            align-items: flex-end;
            .el-order-btn {
              margin-left: 10px;
              width: 145px;
              height: 52px;
              background: @blue;
              font-size: 18px;
              color: rgb( 255, 255, 255 );
              line-height: 57px;
              border-radius: 3px;
              padding: 0;
              border: none;

              &:hover {
                box-shadow: @boxShadow;
              }
              &.disabled {
                background-color: #d9d9dc;
                cursor: default;
                &:hover {
                  box-shadow: none;
                }
              }
            }
          }
        }

      }
      margin-bottom: 25px;
    }


    .interpretPay_title {
      width: 1206px;
      height: 116px;
      background: #ffffff;
      margin: 81px auto 0px;
      padding: 34px 0 0 29px;
      box-sizing: border-box;
      p:nth-child(1) {
        font-size: 19.163px;
        color: rgb( 51, 51, 51 );
      }

      p:nth-child(2) {
        font-size: 13.414px;
        color: rgb( 102, 102, 102 );
        margin-top: 15px;
      }
    }
  }


</style>
